.main-container {
    padding: 15px;
}

.logout {
    margin-bottom: 20px;
    display: block;
    cursor: pointer;
}

.input-box {
    max-width: 300px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
